@font-face {
    font-family: "301 Sans";
    font-weight: 400;
    src:
        url("../fonts/301Sans-Regular.woff") format("woff"),
        url("../fonts/301Sans-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "301 Sans";
    font-weight: 300;
    src:
        url("../fonts/301Sans-Light.woff") format("woff"),
        url("../fonts/301Sans-Light.woff2") format("woff2");
}
@font-face {
    font-family: "301 Sans";
    font-weight: 600;
    src:
        url("../fonts/301Sans-Bold.woff") format("woff"),
        url("../fonts/301Sans-Bold.woff2") format("woff2");
}