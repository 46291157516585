.breadcrumb{
    ul{
        font-size: var(--text-xs);
        color: var(--color-dark);
        li{
            padding: 0;
            background: none;
            display: inline-flex;
            align-items: center;
            svg{
                margin-right: var(--spacer-s);
                opacity: 0.25;
            }
            a, span{
                background: var(--color-light);
                padding: var(--spacer-xs) var(--spacer-s);
                height: 100%;
                display: block;
            }
        }
    }
}