ol, ul{
    @extend .reset;
    list-style: none;
    li{
        &::marker{
            color: var(--color-primary)
        }
        padding: var(--spacer-xs) 0;
    }
    &.inline{
        display: flex; 
        flex-wrap: wrap;
        gap: var(--spacer-s);
        li{
            padding: var(--spacer-xs) var(--spacer-s);
            background-color: color-mix(in srgb, var(--color-primary-dark) 25%, transparent);
        }
    }
    &.lined{
        li:not(:last-of-type){
            border-bottom: 1px solid var(--color-border);
        }
    }
}