svg{
    path{
        stroke: var(--color-primary);
        stroke-width: var(--spacer-xs)
    }
    &.small{
        height: var(--spacer-s);
        width: var(--spacer-s);
    }
    &.xs{
        height: var(--spacer-xs);
        width: var(--spacer-xs);
    }
    &.white path{
        stroke: var(--color-background);
    }
}