h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    margin-top: 0;
    font-weight: 400;
}

h1 {
    font-size: var(--text-xl);
}

header{
    &.inline{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

h2 {
    font-size: var(--text-l);
}

h2,
h3,
h4 {
    margin-bottom: var(--spacer-xs);
}

p {
    @extend .reset;
    color: var(--color-primary-dark);
    &.large{
        font-size: var(--text-sm);
    }
}

p, table{
    &:not(:last-child){
        margin-bottom: var(--spacer-sm);
    }
}

strong {
    font-weight: 600;
}

a {
    &:link,
    &:visited,
    &:active {
        font-weight: 400;
        color: var(--color-primary);
        text-decoration: underline;
        text-decoration-color: var(--color-border);
        text-underline-offset: 2px;
    }
    &:hover{
        text-decoration-color: var(--color-primary);
    }
    &[target="_blank"]{
        @include pseudo-icon('before', var(--spacer-s), 'external');
        display: unset;
        &::before{
            display: inline-block
        }
    }
}