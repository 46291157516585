@mixin base-icon($size, $filename) {
    content: "";
    display: block;
    flex: 0 0 $size;
    width: $size;
    height: $size;
    background: url("data-url:~src/icons/#{$filename}.svg") no-repeat center center;
    background-size: contain;
  }
  
  // Usage: @include pseudo-icon('before', var(--space-medium), 'home');
  @mixin pseudo-icon($position, $size, $filename) {
    display: inline-flex;
    align-items: center;
    @if $position == before {
        &::before {
            margin-right: calc($size /2);
            @include base-icon($size, $filename);
        }
    } @else {
        &::after {
            @include base-icon($size, $filename);
            margin-left: calc($size /2);
        }
    }
}