table{
    border-collapse: collapse;
    text-align: left;
    color: var(--color-dark);
    width: 100%;
    border: 2px solid var(--color-border);
    overflow-x: scroll;
    tbody{
        th{
            font-weight: 400;
            color: var(--color-primary);
        }
        tr, td, td, th{
            border: 1px solid var(--color-border);
        }
    }
    td, th{
        padding: var(--spacer-xs) var(--spacer-s);
    }
    tr{
        &:nth-child(even){
            background: color-mix(in srgb, var(--color-light) 40%, var(--color-background));
        }
    }
}