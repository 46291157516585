.tabs {
  border: 2px solid var(--color-border);
}

[role="tablist"] {
  display: flex;
  background: var(--color-light);
  position: sticky;
  top: 0;
  button {
    background: transparent;
    padding: var(--spacer-s) var(--spacer-m);
    text-transform: none;
  }
}
[role="tab"] {
  position: relative;
  z-index: 1;
}

[role="tab"][aria-selected="true"] {
  z-index: 3;
  background: var(--color-background);
  color: var(--color-primary);
}

[role="tabpanel"] {
  padding: var(--spacer-m);
  z-index: 2;
}

[role="tabpanel"]:focus {
}
