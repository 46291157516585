// Colors
:root{
    --color-primary: rgb(0, 90, 255);
    --color-dark: color-mix(in srgb, var(--color-primary)10%, rgba(0, 0, 0, 0.85));
    --color-primary-dark: color-mix(in srgb, var(--color-primary) 10%, var(--color-dark) );
    --color-light: #f5f1eb;
    --color-background: color-mix(in srgb, var(--color-primary) 0%, white );     
    --color-border: color-mix(in srgb, var(--color-primary) 80%, white );     

    --spacer-xxs: 0.25rem;
    --spacer-xs: 0.5rem;
    --spacer-s: 1rem;
    --spacer-sm: 1.35rem;
    --spacer-m: 2rem;
    --spacer-l: 4rem;
    
    --text-xs: 0.875rem;
    --text-s: 1rem;
    --text-sm: 1.2rem;
    --text-m: 1.5rem;
    --text-l: 2.5rem;
    --text-xl: 3rem;
    
    .dark{
        --color-primary: rgb(88, 255, 247);
        --color-dark: color-mix(in srgb, var(--color-primary) 5%, white);
        --color-primary-dark: color-mix(in srgb, var(--color-primary) 10%, var(--color-dark) );
        --color-light: color-mix(in srgb, var(--color-primary-dark) 10%, var(--color-background) );
        --color-background: color-mix(in srgb, var(--color-primary) 10%, black );    
        --color-border: color-mix(in srgb, var(--color-primary) 40%, black );     
    }
}