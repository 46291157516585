input{
    padding: var(--spacer-xs) var(--spacer-s);
    &[type="text"]{
        border: 2px solid var(--color-primary);
        flex-grow: 1;
        background: transparent;
    }
    &[type="checkbox"]{
        appearance: none;
        padding: 0;
        height: var(--spacer-m);
        width: var(--spacer-m);
        flex: 0 0 var(--spacer-m);
        border: 2px solid var(--color-primary);
        cursor: pointer;
        &:checked{
            background: url('~src/icons/check.svg') no-repeat center center;
            background-size: 75%;        
        }
    }
}

input[type="button"], input[type="submit"], a.button, button{
    padding: var(--spacer-s) var(--spacer-s);
    line-height: 1;
    text-decoration: none;
    border: none;
    outline: none;
    background: var(--color-primary);
    color: var(--color-background);
    font-weight: 400;
    cursor: pointer;
    text-transform: uppercase;
    &:hover{
        background: var(--color-primary-dark);
    }
}

a.button{
    &.icon{
        display: inline-flex;
        gap: var(--spacer-s);
    }
}

input, select, label, button{
    font-family: inherit;
    color: var(--color-primary);
    font-size: var(--text-s);
}

select{
    border: 2px solid var(--color-primary);
    padding: var(--spacer-xs) var(--spacer-s);
    font-size: var(--text-s);
    font-size: 20px;
    padding-right: calc(1.5*var(--spacer-m));
    background: url('~src/icons/chevron_down.svg') no-repeat;
    background-size: var(--spacer-s);
    background-position: right var(--spacer-s) center;
    appearance: none;
}

form{
    & > div{
        &:not(:last-child){
            margin-bottom: var(--spacer-m);
        }
    }
    .wrapper{
        display: flex;
        gap: var(--spacer-s);
        align-items: flex-start;
    }
}