* {
    box-sizing: border-box;
}

html {
    font-family: "301 Sans";
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.75;
    font-size: 17px;
}

@import "variables";
@import "mixins";
@import "reset";
@import "fonts";
@import "typo";
@import "icons";

// tags
@import "tags/lists";
@import "tags/inputs";
@import "tags/tables";

// parts
@import "parts/header";

// components
@import "components/breadcrumb";
@import "components/tabs";

::selection {
    color: var(--color-primary);
    background: var(--color-light);
}

body {
    background: var(--color-background);
    margin: 0;
    padding: 0;
}

body > .wrapper {
    
    &[data-columns="4"]{
    }
    @container (min-width: 850px){
        display: flex;
        gap: var(--spacer-l);
        align-items: stretch;
    }
}

aside {
    width: 40%;
    position: relative;
    height: 100%;
    & > div {
        background: var(--color-light);
        padding: var(--spacer-m);
        position: sticky;
        top: calc(var(--spacer-l) + var(--spacer-l));
        &:not(:last-of-type) {
            margin-bottom: var(--spacer-l);
        }
        color: var(--color-primary);
    }
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

main {
    width: 100%;
    container: sidebar / inline-size;
    @container sidebar (min-width: 850px){
        max-width: 60%;
    }
    article {
        padding: var(--spacer-m) 0;
        &:not(:last-of-type) {
            border-bottom: 1px solid var(--color-border);
        }
        header {
            display: flex;
            gap: var(--spacer-s);
            align-items: center;
            margin-bottom: var(--spacer-xs);
            h3 {
                margin: 0;
            }
            span {
                font-weight: 300;
                font-size: var(--text-xs);
                padding: var(--spacer-xxs) var(--spacer-xs);
                border: 1px dashed var(--color-primary);
                line-height: 1;
                color: var(--color-primary);
            }
        }
    }
}

section {
    padding: var(--spacer-l) 0;
    color: var(--color-primary);
    &.darker{
        background: var(--color-primary);
        color: var(--color-background);
    }
    &.banner {
        header {
            margin-bottom: var(--spacer-m);
        }
        h2 {
            margin: 0;
        }
        .inside {
            background: var(--color-primary);
            color: var(--color-background);
            padding: var(--spacer-m);
            header span {
                font-size: 0.875rem;
                padding: var(--spacer-xxs) var(--spacer-xs);
                border: 1px dashed var(--color-light);
            }
            .check {
                li {
                    display: flex;
                    gap: var(--spacer-s);
                    align-items: center;
                    svg{
                        opacity: 0.5;
                    }
                }
            }
        }
        .appendix {
            background: var(--color-light);
            padding: var(--spacer-m);
            small {
                font-size: var(--text-xs);
            }
            a {
                width: 100%;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        &.light {
            .inside {
                border: 2px solid var(--color-primary);
                color: var(--color-primary);
                background: var(--color-background);
            }
        }
    }
}

footer {
    font-size: 0.875rem;
    font-weight: 300;
    margin-top: var(--spacer-l);
    margin-bottom: var(--spacer-l);
    ul {
        display: flex;
        gap: var(--spacer-s);
    }
}

input[type="checkbox"]#modeSwitch {
    cursor: pointer;
    height: var(--spacer-m);
    width: var(--spacer-m);
    outline: 2px solid var(--color-primary);
    border: 0;
    appearance: none;
    transition: all 0.25s ease-in-out;
    border-radius: 100%;
    background: url("~src/icons/lightmode.svg") no-repeat center;
    background-size: 75%;
    &:checked {
        outline: 2px solid var(--color-primary-dark);
        background: url("~src/icons/darkmode.svg") no-repeat center;
        background-size: 75%;
    }
}

section.news {
    background: var(--color-light);
    padding: var(--spacer-m) 0;
    margin: var(--spacer-m) 0;
    ul {
        container-type: inline-size;
        @container (min-width: 850px){
            display: grid;
            gap: var(--spacer-sm);
            grid-template-columns: repeat(4, 1fr);
        }
        article {
            border: 2px solid var(--color-border);
            padding: var(--spacer-sm);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: var(--color-background);
            p{
                font-size: var(--text-xs);
            }
            &.unread {
                border: 2px solid var(--color-primary);
                position: relative;
                &::before{
                    position: absolute;
                    top: 0;
                    right: 0;
                    content: 'NEU';
                    font-size: var(--text-xs);
                    background: var(--color-primary);
                    color: var(--color-background);
                    display: block;
                    padding: var(--spacer-xs) var(--spacer-s);
                    line-height: 1;
                }
            }
        }
    }
}

.container{
    padding-left: 5vw;
    padding-right: 5vw;
}

section{
    &.hero{
        max-width: 100vw;
        overflow: hidden;
        position: relative;
        padding: calc(1*var(--spacer-l)) 0;
        margin-bottom: var(--spacer-m);
        background: var(--color-light);
        display: flex;
        flex-direction: column;
        .inside{
            z-index: 100;
            .container{
                margin-top: var(--spacer-l);
                width: 70%;
            }
            .button{
                margin-top: var(--spacer-m);
                font-size: var(--text-s);
            }
            img{
                height: 30rem;
                display: block;
            }
        }
        .fassade{
            display: flex;
            gap: var(--spacer-s);
            align-items: flex-end;
            justify-content: center;
            .lamp{
                height: 7rem;
            }
        }
        .street{
            display: block;
            z-index: 10;
            margin-top: -10px;
            height: 6rem;
            background: url(../assets/street.svg) repeat-x top;
            background-size: contain;
        }
        h2{
            font-size: var(--text-l);
            font-weight: 600;
        }
        p{
            font-size: var(--text-sm);
            font-weight: 300;
        }
    }
}

body > .info{
    background: var(--color-primary);
    color: var(--color-background);
    padding: var(--spacer-xs) 0;
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--spacer-xs);
        p{
            color: var(--color-background);
            margin: 0;
        }
    }
}

.alphabet{
    display: grid;
    gap: var(--spacer-s);
    container-type: inline-size;
    @container (min-width: 850px){
        grid-template-columns: repeat(6, 1fr);

    }
    li{
        font-size: var(--text-xl);
        display: flex;
        gap: var(--spacer-sm);
        color: var(--color-background);
        span{
            font-weight: 300;
        }
    }
}