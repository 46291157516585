* {
  box-sizing: border-box;
}

html {
  text-rendering: geometricprecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "301 Sans";
  font-size: 17px;
  line-height: 1.75;
}

:root {
  --color-primary: #005aff;
  --color-dark: color-mix(in srgb, var(--color-primary) 10%, #000000d9);
  --color-primary-dark: color-mix(in srgb, var(--color-primary) 10%, var(--color-dark));
  --color-light: #f5f1eb;
  --color-background: color-mix(in srgb, var(--color-primary) 0%, white);
  --color-border: color-mix(in srgb, var(--color-primary) 80%, white);
  --spacer-xxs: .25rem;
  --spacer-xs: .5rem;
  --spacer-s: 1rem;
  --spacer-sm: 1.35rem;
  --spacer-m: 2rem;
  --spacer-l: 4rem;
  --text-xs: .875rem;
  --text-s: 1rem;
  --text-sm: 1.2rem;
  --text-m: 1.5rem;
  --text-l: 2.5rem;
  --text-xl: 3rem;
}

:root .dark {
  --color-primary: #58fff7;
  --color-dark: color-mix(in srgb, var(--color-primary) 5%, white);
  --color-primary-dark: color-mix(in srgb, var(--color-primary) 10%, var(--color-dark));
  --color-light: color-mix(in srgb, var(--color-primary-dark) 10%, var(--color-background));
  --color-background: color-mix(in srgb, var(--color-primary) 10%, black);
  --color-border: color-mix(in srgb, var(--color-primary) 40%, black);
}

.reset, ol, ul, p {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "301 Sans";
  font-weight: 400;
  src: url("301Sans-Regular.2ee7f9b0.woff") format("woff"), url("301Sans-Regular.fb188097.woff2") format("woff2");
}

@font-face {
  font-family: "301 Sans";
  font-weight: 300;
  src: url("301Sans-Light.1455f04d.woff") format("woff"), url("301Sans-Light.0633a7ea.woff2") format("woff2");
}

@font-face {
  font-family: "301 Sans";
  font-weight: 600;
  src: url("301Sans-Bold.2421afe0.woff") format("woff"), url("301Sans-Bold.2ea098e4.woff2") format("woff2");
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin-top: 0;
  font-weight: 400;
}

h1 {
  font-size: var(--text-xl);
}

header.inline {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

h2 {
  font-size: var(--text-l);
}

h2, h3, h4 {
  margin-bottom: var(--spacer-xs);
}

p {
  color: var(--color-primary-dark);
}

p.large {
  font-size: var(--text-sm);
}

p:not(:last-child), table:not(:last-child) {
  margin-bottom: var(--spacer-sm);
}

strong {
  font-weight: 600;
}

a:link, a:visited, a:active {
  color: var(--color-primary);
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-border);
  text-decoration-color: var(--color-border);
  text-underline-offset: 2px;
  font-weight: 400;
}

a:hover {
  -webkit-text-decoration-color: var(--color-primary);
  text-decoration-color: var(--color-primary);
}

a[target="_blank"] {
  display: inline-flex;
  display: unset;
  align-items: center;
}

a[target="_blank"]:before {
  margin-right: calc(var(--spacer-s) / 2);
  content: "";
  flex: 0 0 var(--spacer-s);
  width: var(--spacer-s);
  height: var(--spacer-s);
  background: url("data:image/svg+xml,%3Csvg%20id%3D%22a%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2264%22%20height%3D%2264%22%3E%3Cpath%20style%3D%22fill%3Anone%3Bstroke%3A%2303f%3Bstroke-miterlimit%3A10%3Bstroke-width%3A6px%22%20d%3D%22M58.75%2028.23V5.79H35.8M24.44%205.79h-14.1a4.59%204.59%200%200%200-4.59%204.59V54.2a4.59%204.59%200%200%200%204.59%204.59h43.82a4.59%204.59%200%200%200%204.59-4.59V39.59M19.52%2045.01%2057.35%207.19%22%2F%3E%3C%2Fsvg%3E") center / contain no-repeat;
  display: inline-block;
}

svg path {
  stroke: var(--color-primary);
  stroke-width: var(--spacer-xs);
}

svg.small {
  height: var(--spacer-s);
  width: var(--spacer-s);
}

svg.xs {
  height: var(--spacer-xs);
  width: var(--spacer-xs);
}

svg.white path {
  stroke: var(--color-background);
}

ol, ul {
  list-style: none;
}

ol li, ul li {
  padding: var(--spacer-xs) 0;
}

ol li::marker, ul li::marker {
  color: var(--color-primary);
}

ol.inline, ul.inline {
  gap: var(--spacer-s);
  flex-wrap: wrap;
  display: flex;
}

ol.inline li, ul.inline li {
  padding: var(--spacer-xs) var(--spacer-s);
  background-color: color-mix(in srgb, var(--color-primary-dark) 25%, transparent);
}

ol.lined li:not(:last-of-type), ul.lined li:not(:last-of-type) {
  border-bottom: 1px solid var(--color-border);
}

input {
  padding: var(--spacer-xs) var(--spacer-s);
}

input[type="text"] {
  border: 2px solid var(--color-primary);
  background: none;
  flex-grow: 1;
}

input[type="checkbox"] {
  appearance: none;
  height: var(--spacer-m);
  width: var(--spacer-m);
  flex: 0 0 var(--spacer-m);
  border: 2px solid var(--color-primary);
  cursor: pointer;
  padding: 0;
}

input[type="checkbox"]:checked {
  background: url("check.307d37d3.svg") center / 75% no-repeat;
}

input[type="button"], input[type="submit"], a.button, button {
  padding: var(--spacer-s) var(--spacer-s);
  background: var(--color-primary);
  color: var(--color-background);
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  outline: none;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}

input[type="button"]:hover, input[type="submit"]:hover, a.button:hover, button:hover {
  background: var(--color-primary-dark);
}

a.button.icon {
  gap: var(--spacer-s);
  display: inline-flex;
}

input, select, label, button {
  color: var(--color-primary);
  font-family: inherit;
  font-size: var(--text-s);
}

select {
  border: 2px solid var(--color-primary);
  padding: var(--spacer-xs) var(--spacer-s);
  font-size: var(--text-s);
  padding-right: calc(1.5 * var(--spacer-m));
  background: url("chevron_down.869b7435.svg") no-repeat;
  background-size: var(--spacer-s);
  background-position: right var(--spacer-s) center;
  appearance: none;
  font-size: 20px;
}

form > div:not(:last-child) {
  margin-bottom: var(--spacer-m);
}

form .wrapper {
  gap: var(--spacer-s);
  align-items: flex-start;
  display: flex;
}

table {
  border-collapse: collapse;
  text-align: left;
  color: var(--color-dark);
  border: 2px solid var(--color-border);
  width: 100%;
  overflow-x: scroll;
}

table tbody th {
  color: var(--color-primary);
  font-weight: 400;
}

table tbody tr, table tbody td, table tbody td, table tbody th {
  border: 1px solid var(--color-border);
}

table td, table th {
  padding: var(--spacer-xs) var(--spacer-s);
}

table tr:nth-child(2n) {
  background: color-mix(in srgb, var(--color-light) 40%, var(--color-background));
}

body > header {
  padding: var(--spacer-m) 0;
  color: var(--color-primary);
  background: color-mix(in srgb, var(--color-background) 90%, transparent);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 900;
  position: sticky;
  top: 0;
}

body > header .container {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

body > header nav ul {
  position: relative;
  container-type: inline-size;
}

@container (width >= 850px) {
  body > header nav ul {
    gap: var(--spacer-m);
    align-items: center;
    display: flex;
  }
}

body > header nav li h1, body > header nav li a {
  margin: 0;
  line-height: 1;
}

body > header nav li span {
  opacity: .5;
}

body > header nav li:hover span, body > header nav li.active span {
  opacity: 1;
}

body > header nav li.active:hover:before {
  content: none;
}

body > header nav li a:link {
  font-size: var(--text-m);
  text-underline-offset: 5px;
}

body > header nav li.notification {
  display: inline-flex;
}

body > header nav li.notification > div {
  height: var(--spacer-sm);
  width: var(--spacer-sm);
  font-size: var(--text-s);
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  box-sizing: content-box;
  color: var(--color-background);
  text-align: center;
  margin-left: var(--spacer-xs);
  font-weight: 400;
  line-height: var(--spacer-sm);
  border-radius: 100%;
  text-decoration-color: #0000;
}

.breadcrumb ul {
  font-size: var(--text-xs);
  color: var(--color-dark);
}

.breadcrumb ul li {
  background: none;
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.breadcrumb ul li svg {
  margin-right: var(--spacer-s);
  opacity: .25;
}

.breadcrumb ul li a, .breadcrumb ul li span {
  background: var(--color-light);
  padding: var(--spacer-xs) var(--spacer-s);
  height: 100%;
  display: block;
}

.tabs {
  border: 2px solid var(--color-border);
}

[role="tablist"] {
  background: var(--color-light);
  display: flex;
  position: sticky;
  top: 0;
}

[role="tablist"] button {
  padding: var(--spacer-s) var(--spacer-m);
  text-transform: none;
  background: none;
}

[role="tab"] {
  z-index: 1;
  position: relative;
}

[role="tab"][aria-selected="true"] {
  z-index: 3;
  background: var(--color-background);
  color: var(--color-primary);
}

[role="tabpanel"] {
  padding: var(--spacer-m);
  z-index: 2;
}

::selection {
  color: var(--color-primary);
  background: var(--color-light);
}

body {
  background: var(--color-background);
  margin: 0;
  padding: 0;
}

@container (width >= 850px) {
  body > .wrapper {
    gap: var(--spacer-l);
    align-items: stretch;
    display: flex;
  }
}

aside {
  width: 40%;
  height: 100%;
  position: relative;
}

aside > div {
  background: var(--color-light);
  padding: var(--spacer-m);
  top: calc(var(--spacer-l)  + var(--spacer-l));
  color: var(--color-primary);
  position: sticky;
}

aside > div:not(:last-of-type) {
  margin-bottom: var(--spacer-l);
}

aside header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

main {
  width: 100%;
  container: sidebar / inline-size;
}

@container sidebar (width >= 850px) {
  main {
    max-width: 60%;
  }
}

main article {
  padding: var(--spacer-m) 0;
}

main article:not(:last-of-type) {
  border-bottom: 1px solid var(--color-border);
}

main article header {
  gap: var(--spacer-s);
  margin-bottom: var(--spacer-xs);
  align-items: center;
  display: flex;
}

main article header h3 {
  margin: 0;
}

main article header span {
  font-weight: 300;
  font-size: var(--text-xs);
  padding: var(--spacer-xxs) var(--spacer-xs);
  border: 1px dashed var(--color-primary);
  color: var(--color-primary);
  line-height: 1;
}

section {
  padding: var(--spacer-l) 0;
  color: var(--color-primary);
}

section.darker {
  background: var(--color-primary);
  color: var(--color-background);
}

section.banner header {
  margin-bottom: var(--spacer-m);
}

section.banner h2 {
  margin: 0;
}

section.banner .inside {
  background: var(--color-primary);
  color: var(--color-background);
  padding: var(--spacer-m);
}

section.banner .inside header span {
  padding: var(--spacer-xxs) var(--spacer-xs);
  border: 1px dashed var(--color-light);
  font-size: .875rem;
}

section.banner .inside .check li {
  gap: var(--spacer-s);
  align-items: center;
  display: flex;
}

section.banner .inside .check li svg {
  opacity: .5;
}

section.banner .appendix {
  background: var(--color-light);
  padding: var(--spacer-m);
}

section.banner .appendix small {
  font-size: var(--text-xs);
}

section.banner .appendix a {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

section.banner.light .inside {
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  background: var(--color-background);
}

footer {
  margin-top: var(--spacer-l);
  margin-bottom: var(--spacer-l);
  font-size: .875rem;
  font-weight: 300;
}

footer ul {
  gap: var(--spacer-s);
  display: flex;
}

input[type="checkbox"]#modeSwitch {
  cursor: pointer;
  height: var(--spacer-m);
  width: var(--spacer-m);
  outline: 2px solid var(--color-primary);
  appearance: none;
  background: url("lightmode.d5425b0f.svg") center / 75% no-repeat;
  border: 0;
  border-radius: 100%;
  transition: all .25s ease-in-out;
}

input[type="checkbox"]#modeSwitch:checked {
  outline: 2px solid var(--color-primary-dark);
  background: url("darkmode.c53f900b.svg") center / 75% no-repeat;
}

section.news {
  background: var(--color-light);
  padding: var(--spacer-m) 0;
  margin: var(--spacer-m) 0;
}

section.news ul {
  container-type: inline-size;
}

@container (width >= 850px) {
  section.news ul {
    gap: var(--spacer-sm);
    grid-template-columns: repeat(4, 1fr);
    display: grid;
  }
}

section.news ul article {
  border: 2px solid var(--color-border);
  padding: var(--spacer-sm);
  background: var(--color-background);
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

section.news ul article p {
  font-size: var(--text-xs);
}

section.news ul article.unread {
  border: 2px solid var(--color-primary);
  position: relative;
}

section.news ul article.unread:before {
  content: "NEU";
  font-size: var(--text-xs);
  background: var(--color-primary);
  color: var(--color-background);
  padding: var(--spacer-xs) var(--spacer-s);
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.container {
  padding-left: 5vw;
  padding-right: 5vw;
}

section.hero {
  padding: calc(1 * var(--spacer-l)) 0;
  margin-bottom: var(--spacer-m);
  background: var(--color-light);
  flex-direction: column;
  max-width: 100vw;
  display: flex;
  position: relative;
  overflow: hidden;
}

section.hero .inside {
  z-index: 100;
}

section.hero .inside .container {
  margin-top: var(--spacer-l);
  width: 70%;
}

section.hero .inside .button {
  margin-top: var(--spacer-m);
  font-size: var(--text-s);
}

section.hero .inside img {
  height: 30rem;
  display: block;
}

section.hero .fassade {
  gap: var(--spacer-s);
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

section.hero .fassade .lamp {
  height: 7rem;
}

section.hero .street {
  z-index: 10;
  background: url("street.5ca14a98.svg") top / contain repeat-x;
  height: 6rem;
  margin-top: -10px;
  display: block;
}

section.hero h2 {
  font-size: var(--text-l);
  font-weight: 600;
}

section.hero p {
  font-size: var(--text-sm);
  font-weight: 300;
}

body > .info {
  background: var(--color-primary);
  color: var(--color-background);
  padding: var(--spacer-xs) 0;
}

body > .info .container {
  justify-content: space-between;
  align-items: center;
  gap: var(--spacer-xs);
  display: flex;
}

body > .info .container p {
  color: var(--color-background);
  margin: 0;
}

.alphabet {
  gap: var(--spacer-s);
  display: grid;
  container-type: inline-size;
}

@container (width >= 850px) {
  .alphabet {
    grid-template-columns: repeat(6, 1fr);
  }
}

.alphabet li {
  font-size: var(--text-xl);
  gap: var(--spacer-sm);
  color: var(--color-background);
  display: flex;
}

.alphabet li span {
  font-weight: 300;
}

/*# sourceMappingURL=index.e6af81e5.css.map */
