
body {
    & > header {
        padding: var(--spacer-m) 0;
        position: sticky;
        top: 0;
        color: var(--color-primary);
        background: color-mix(in srgb, var(--color-background) 90%, transparent);
        backdrop-filter: blur(10px);
        z-index: 900;
        .container{
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }
        nav {
            ul {
                position: relative;
                container-type: inline-size;
                @container (min-width: 850px){
                    display: flex;
                    gap: var(--spacer-m);
                    align-items: center;
                }
            }
            li {
                h1, a {
                    line-height: 1;
                    margin: 0;
                }
                span {
                    opacity: 0.5;
                    // color: transparent;
                    // -webkit-text-stroke-width: 0.1rem;
                    // -webkit-text-stroke-color: var(--color-primary);
                }
                &:hover {
                    span {
                        opacity: 1;
                    }
                }
                &.active {
                    span {
                        opacity: 1;
                    }
                    &:hover {
                        &:before {
                            content: none;
                        }
                    }
                }
                a:link {
                    font-size: var(--text-m);
                    text-underline-offset: 5px;
                }
                &.notification {
                    display: inline-flex;
                    & > div {
                        height: var(--spacer-sm);
                        width: var(--spacer-sm);
                        font-size: var(--text-s);
                        font-weight: 400;
                        border-radius: 100%;
                        background: var(--color-primary);
                        border: 2px solid var(--color-primary);
                        box-sizing: content-box;
                        color: var(--color-background);
                        text-align: center;
                        margin-left: var(--spacer-xs);
                        line-height: var(--spacer-sm);
                        text-decoration-color: transparent;
                    }
                }
            }
        }
    }
}